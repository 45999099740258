import axios from '@/plugins/axios'

/**
 * Activate campaign
 */
const activateCampaign = async (id) => {
  try {
    const response = await axios.post(`campaigns/${id}/activate`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Create campaign
 */
const createCampaign = async (form) => {
  try {
    const response = await axios.post('campaigns', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Destroy campaign
 */
const destroyCampaign = async (id) => {
  try {
    const response = await axios.post(`campaigns/${id}`, {
      _method: 'DELETE'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get single campaign
 */
const getCampaign = async id => {
  try {
    const response = await axios.get(`campaigns/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get campaigns
 */
const getCampaigns = async ({
  advertiserId = null,
  companyId = null,
  name = null,
  status = null,
  page = 1
}) => {
  try {
    const params = {
      page: page
    }
    if (companyId) params.company_id = companyId
    if (advertiserId) params.advertiser_id = advertiserId
    if (name) params.name = name
    if (status) params.status = status
    const response = await axios.get('campaigns', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get campaigns summary
 */
const getCampaignsSummary = async ({
  campaignIds = [],
  startDate = null,
  endDate = null,
  lineIds = []
}) => {
  try {
    const params = {}
    if (campaignIds.length > 0) params.campaign_ids = campaignIds
    if (lineIds.length > 0) params.line_ids = lineIds
    if (startDate) params.start_date = startDate
    if (endDate) params.end_date = endDate
    const response = await axios.get('campaigns/summary', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Pause campaign
 */
const pauseCampaign = async (id) => {
  try {
    const response = await axios.post(`campaigns/${id}/pause`, {
      _method: 'DELETE'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Retry creation on platform
 */
const retryCreationOnPlatform = async id => {
  try {
    const response = await axios.post(`campaigns/${id}/retry`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update campaign
 */
const updateCampaign = async (id, form) => {
  try {
    form._method = 'PUT'
    const response = await axios.post(`campaigns/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  activateCampaign,
  createCampaign,
  destroyCampaign,
  getCampaign,
  getCampaigns,
  getCampaignsSummary,
  pauseCampaign,
  retryCreationOnPlatform,
  updateCampaign
}
